import React from 'react'
import { Form } from 'react-bootstrap'
import parse from 'html-react-parser';

const ConfirmItem = (props) => { // <ConfirmItem id=""></ConfirmItem>
    return (
        <Form.Group controlId={props.id}>
            <Form.Label>{props.children}</Form.Label>
            <br/>
        </Form.Group>
    )
}

const FormItem = (props) => { // <FormItem id="" name="" label="" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
    return (
        <Form.Group controlId={props.id}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control type={props?.type} as={props.as} placeholder={props?.placeholder} name={props.name} onChange={props.handleChange} onBlur={props.handleBlur} value={props.values[props.name]} />
            <Form.Text>
                {props.errors[props.name] && props.touched[props.name] && props.errors[props.name]}
            </Form.Text>
            {props.children}
            <br/>
        </Form.Group>
    )
}

const FormSelectItem = (props) => { // <FormSelectItem id="" name="" label="" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormSelectItem>
    return (
        <Form.Group controlId={props.id}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control name={props.name} onChange={props.handleChange} onBlur={props.handleBlur} value={props.values[props.name]} as="select">
                {props.children}
            </Form.Control>
            <Form.Text>
                {props.errors[props.name] && props.touched[props.name] && props.errors[props.name]}
            </Form.Text>
            <br/>
        </Form.Group>
    )
}

const FormCheckbox = (props) => {
    return (
        <Form.Group controlId={props.id}>
            <Form.Check name={props.name} onChange={props.handleChange} onBlur={props.handleBlur} value={props.values[props.name]} label={props.label} />
            <br/>
        </Form.Group>
    )
}

export { ConfirmItem, FormItem, FormSelectItem, FormCheckbox };