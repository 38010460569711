import React, { Component } from 'react';
import './App.css';
import { sendPostRequest } from './components/api';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { MainContent } from './components/MainContent'
import { clientkey } from './components/clientkey'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Block404 = () => {
  return (
    <div className="content-div">
      <p>Something went wrong! This page does not exist yet.</p>
    </div>
  )
}

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Routes>
            {/* <Route path="/test" element={<MainContentNoDemo agency="CECIL" agencyName="Cecil County Health Department" contactphone="(410) 996-1005" title="Schedule Vital Records Appointment" />} /> */}
            <Route path="/request/:agency/:oid" element={<MainContent type="request" />} />
            <Route path="/form/:agency/:oid" element={<MainContent type="form" />} />
            <Route path="/" element={<Block404 />} />
          </Routes>
        </Router>
        <GoogleReCaptchaProvider reCaptchaKey='6LcDdFslAAAAAIgRAfDfSaLYT7_2AxfOGP_AQorD'>
          <GoogleReCaptcha onVerify={(token) => {
            sendPostRequest('verify', {
              'clientkey': clientkey,
              'response': token
            })
          }} />
        </GoogleReCaptchaProvider>
      </>
    );
  }

}

export default App;
