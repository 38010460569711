import axios from 'axios';

const url = `https://forms-api.pattrac.app/`;
// const url = `http://127.0.0.1:5000/`;

const sendGetRequest = async (endpoint, params=null) => {
  // console.log('GET')
  // console.log(url + endpoint)
  // console.dir(params)
  return axios({
    url: url + endpoint,
    method: 'get',
    headers: { 'Ocp-Apim-Subscription-Key': "711717ef83024043897e7c5032688884" },
    params: params
  }).then(response => {
    // console.log(response);
    return response.data;
  })
};

const sendPostRequest = async (endpoint, data) => {
    // console.log('POST')
    // console.log(url + endpoint)
    // console.dir(data)
    return axios({
      url: url + endpoint,
      method: 'post',
      headers: { 'Ocp-Apim-Subscription-Key': "711717ef83024043897e7c5032688884" },
      data: data
    }).then(response => {
      // console.log(response);
      return response.data;
    })
};

export { sendGetRequest, sendPostRequest };