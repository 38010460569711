import * as Yup from 'yup'

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const validationSchema = Yup.object().shape({
  fname: Yup.string()
    .min(2, "*First name must be longer than 2 characters")
    .max(100, "*First name cannot be longer than 100 characters")
    .required("*First name is required"),
  lname: Yup.string()
    .min(2, "*Last name must be longer than 2 characters")
    .max(100, "*Last name cannot be longer than 100 characters")
    .required("*Last name is required"),
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email cannot be longer than 100 characters")
    .required("*Email is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "*Phone number is not valid")
    .required("*Phone number is required"),
  // address1: Yup.string()
  //   .min(2, "*Address 1 must be longer than 2 characters")
  //   .max(100, "*Address 1 cannot be longer than 100 characters")
  //   .required("*Address 1 is required"),
  // address2: Yup.string()
  //   .min(2, "*Address 2 must be longer than 2 characters")
  //   .max(100, "*Address 2 cannot be longer than 100 characters"),
  // city: Yup.string()
  //   .min(2, "*City must be longer than 2 characters")
  //   .max(100, "*City cannot be longer than 100 characters")
  //   .required("*City is required"),
  // zip: Yup.string()
  //   .min(2, "*Zip code must be longer than 2 characters")
  //   .max(100, "*Zip code cannot be longer than 100 characters")
  //   .required("*Zip code is required"),
  // sex: Yup.string()
  //   .test('non-default', '*Sex selection is required', val => val !== 'DEFAULT'),
  // race: Yup.string()
  //   .test('non-default', '*Race selection is required', val => val !== 'DEFAULT'),
  slot: Yup.string()
    .test('non-default', '*Timeslot selection is required', val => val !== 'DEFAULT'),
  event: Yup.string()
    .test('non-default', '*Date selection is required', val => val !== 'DEFAULT')
  // birthdate: Yup.date()
  //   .required("*Birth date is required")
  //   .min(new Date("1/1/1800"), "*Birth date out of range")
  //   .max(new Date("12/31/2050"), "*Birth date out of range")
});

export { validationSchema }