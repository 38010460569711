import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Button, Spinner } from 'react-bootstrap'
import { sendGetRequest, sendPostRequest } from './api';
// import { statelist } from './statelist'
import { validationSchema } from './validation'
import { clientkey } from './clientkey'
import { ConfirmItem, FormItem, FormSelectItem, FormCheckbox } from './FormComponents'
import { useParams } from "react-router-dom";
// import { placeholder } from '@babel/types';
import parse from 'html-react-parser';

const MainContent = (props) => {
  let { agency, oid } = useParams();
  const [ dataDefault, setDataDefault ] = useState({});
  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ agencyName, setAgencyName ] = useState('');
  const [ imageURL, setImageURL ] = useState('');
  const [ confirmFlag, setConfirmFlag ] = useState('');
  const [ confirmString, setConfirmString ] = useState('');
  // const [ formID, setFormID ] = useState('');
  const [ fields, setFields ] = useState([]);
  const [ initialState, setInitialState ] = useState(true);
  const [ confirmState, setConfirmState ] = useState(false);
  const [ pendingState, setPendingState ] = useState(false);
  const [ failState, setFailState ] = useState(false);
  const [ confirmationID, setConfirmationID ] = useState('');

  const submitData = (values) => {
    // console.log(JSON.stringify(values));
    setPendingState(true);
    sendPostRequest('update', {
      'clientkey': clientkey,
      'ref': oid,
      'agency': agency,
      'type': props.type,
      'data': JSON.stringify(values)
    }).then((data) => {
      // console.dir(data);
      setConfirmState(true);
      setConfirmationID(data.id);
    }).catch((err) => {
      // console.log(err);
      setFailState(true);
    })
  }

  function returnDefaults(fields) {
    let foo = {};
    for (let field of fields) {
      if (field.name)
        foo[field.name] = '';
      if (field.type === 6)
        foo[field.name] = false;
      if (field.type === 15)
        foo[field.name] = field.name;
    }
    foo['validation'] = false;
    return foo
  }

  useEffect(() => {
    sendGetRequest('init', { 'clientkey': clientkey, 'agency': agency, 'ref': oid, 'type': props.type })
      .then((data) => {
        setTitle(data.form_name);
        setDescription(data.form_description);
        setFields(data.fields);
        setAgencyName(data.agency_name);
        setImageURL(data.image_url);
        setConfirmFlag(data.confirm_flag);
        setConfirmString(data.confirm_string);
        // setFormID(data.form_id);
        setDataDefault(returnDefaults(data.fields));
        setInitialState(false);
      }).catch((err) => {
        setFailState(true);
        setInitialState(false);
      })
  }, [agency, oid, props.type])

  function renderSwitch(index, field, values, handleChange, handleBlur, errors, touched) {
    switch(field.type) {
      case 0: // single line text
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder={field.placeholder} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 1: // paragraph text
        return <FormItem as="textarea" id={"userField"+index} name={field.name} label={field.prompt} placeholder={field.placeholder} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 2: // label
        return <ConfirmItem id={"formLabel"+index}>{field.prompt}</ConfirmItem>
      case 3: // section divider
        return <hr/>
      case 4: // page break
        return <br/>
      case 5: // date
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder="MM/DD/YYYY" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 6: // checkbox
        return <FormCheckbox id={"userField"+index} name={field.name} label={field.prompt} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
      case 7: // dropdown
        return <FormSelectItem id={"userField"+index} name={field.name} label={field.prompt} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}>
          <option key={field.placeholder} value={field.placeholder}>{field.placeholder}</option>
          {field.options.map((foo) =>
            <option id={"userOption"+index+foo} key={foo} value={foo}>{foo}</option>
          )}
        </FormSelectItem>
      case 8: // multiple choice (WIP)
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder={field.placeholder} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 9: // number
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder={field.placeholder} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 10: // email
        return <FormItem type="email" id={"userField"+index} name={field.name} label={field.prompt} placeholder="someone@pattrac.info" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 11: // phone
        return <FormItem type="phone" id={"userField"+index} name={field.name} label={field.prompt} placeholder="(XXX) XXX-XXXX" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 12: // address
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder="1234 Street Rd" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 13: // website
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder="https://www.pattrac.app/" values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
      case 14: // file upload
        return <ConfirmItem id={"formLabel"+index}>File upload has not been implemented</ConfirmItem>
      case 15: // hidden field
        return null
      case 16: // signature
        return <ConfirmItem id={"formLabel"+index}>Signature has not been implemented</ConfirmItem>
      case 17: // likert scale
        return <ConfirmItem id={"formLabel"+index}>Likert scale has not been implemented</ConfirmItem>
      case 18: // net promoter scale
        return <ConfirmItem id={"formLabel"+index}>Net promoter scale has not been implemented</ConfirmItem>
      case 19: // html parse
        return <ConfirmItem id={"formHTML"+index}>{parse(field.prompt)}</ConfirmItem>
      default:
        return <FormItem id={"userField"+index} name={field.name} label={field.prompt} placeholder={field.placeholder} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched}></FormItem>
    }
  }

  return (
    <div className="content-div">
      {initialState ? <Spinner animation="border" role="status" /> :
        failState ? 
          <p>An error has occurred. Please try again, or contact the health department if the issue persists.</p>
        : 
          <div>
            <div className="image-div">
              {/* <img alt="" src="/images/public-health-shield-half.png" style={{display: "block", float: "left"}} /> */}
              <img alt="" src={imageURL} style={{display: "block", float: "left"}} />
            </div>
            <div className="title-div">
              <h2>{title}</h2>
              <h4>{agencyName}</h4>
            </div>
            <br/>
            <Formik
              initialValues={dataDefault}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty
                /* and other goodies */
              }) => (
                confirmState || pendingState ?
                  confirmState ?
                    <Form>
                      <ConfirmItem id="userConfirmMessage">Your response has been submitted. If you have any questions please contact the health department and refer to your confirmation ID #{confirmationID}. Please print/screenshot this confirmation page for your personal records.</ConfirmItem>
                    </Form>
                  :
                    <Form>
                      <ConfirmItem id="userPendingMessage">Your Request is being processed. Please do not close this page.</ConfirmItem>
                    </Form>
                :
                  <Form>
                    <ConfirmItem id="formDescription">{parse(description)}</ConfirmItem>
                    <br/>
                    {fields.map((field, index) => (
                      renderSwitch(index, field, values, handleChange, handleBlur, errors, touched)
                    ))}
                    <br/>
                    { confirmFlag
                      ? <FormCheckbox id="userValidation" name="validation" label={confirmString} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
                      : null
                    }
                    <Button variant="primary" disabled={confirmFlag && !values.validation} onClick={() => submitData(values)}>Submit</Button>
                  </Form>          
              )}
            </Formik>
          </div>
      }
    </div>
  );
}

export { MainContent };
